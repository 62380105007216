import { TranslationsContext } from '@teliaee/telia-translate';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Container, MainContent, Notice, Spinner } from 'telia-front-react';

import { QueryParam } from './Constants';
import PrePaidView from './pages/PrePaidView';
import { useStores } from './stores/RootStore';
import StatusUtil from './utils/StatusUtil';

interface IMainContentProps {
  hasError: boolean;
}

const Content = observer((props: IMainContentProps) => {
  const { languageStore, routeStore } = useStores();
  const langPaths = ['', 'et', 'en', 'ru'];
  const status = StatusUtil.getStatus(routeStore.queryParam(QueryParam.Status));
  const lang = languageStore.currentLanguage;
  const translate = React.useContext(TranslationsContext).translate;
  const translations = React.useContext(TranslationsContext).translations;

  if (props.hasError) {
    return (
      <MainContent>
        <Container>
          <Notice type="error">{translate('prepaid.generalError')}</Notice>
        </Container>
      </MainContent>
    );
  }

  if (Object.keys(translations).length == 0) {
    return <Spinner />;
  }

  return (
    <MainContent>
      <Container>
        <Helmet title={translate('site.title')} htmlAttributes={{ lang: lang }} />
        <BrowserRouter>
          <Routes>
            {langPaths.map((basePath, i) => (
              <React.Fragment key={`${basePath}${i}`}>
                <Route path={basePath} element={<PrePaidView status={status} />} />
                <Route path={`${basePath}/payment-success`} element={<PrePaidView status={'success'} />} />
                <Route path={`${basePath}/payment-cancelled`} element={<PrePaidView status={'cancelled'} />} />
                <Route path={`${basePath}/payment-processing`} element={<PrePaidView status={'processing'} />} />
              </React.Fragment>
            ))}
          </Routes>
        </BrowserRouter>
      </Container>
    </MainContent>
  );
});

export default Content;
