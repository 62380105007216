import { TranslationsContext } from '@teliaee/telia-translate';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Notice, Separator } from 'telia-front-react';

import { useStores } from '../stores/RootStore';

interface IStatusNoticeProps {
  status?: PaymentStatus;
}

const StatusNotice = observer((props: IStatusNoticeProps) => {
  const { posPaymentStore } = useStores();
  const { status } = props;

  const ResolveStatus = (): JSX.Element => {
    const translate = React.useContext(TranslationsContext).translate;
    if (status === 'success') {
      return <>{translate('prepaid.success')}</>;
    } else if (status === 'cancelled') {
      return <>{translate('prepaid.cancelled')}</>;
    } else if (status === 'processing') {
      return <>{translate('prepaid.processing')}</>;
    } else if (posPaymentStore.failedPosRequest) {
      return <>{translate('prepaid.pos-payment.failed-to-execute')}</>;
    }
    return null;
  };

  const statusType = (): 'error' | 'success' => {
    const status = props.status;
    if (status === 'success' || status === 'processing') return 'success';
    return 'error';
  };

  return (
    <>
      {ResolveStatus() && (
        <>
          <Notice type={statusType()}>{ResolveStatus()}</Notice>
          <Separator color="invisible" spacing="xsmall" />
        </>
      )}
    </>
  );
});

export default StatusNotice;
