import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';

import { EmailValidation } from '../stores/models/EmailValidation';
import { IPopulationRegistryPerson } from '../stores/models/IPopulationRegistryPerson';
import { IPrepaidInfo } from '../stores/models/IPrepaidInfo';
import { IPrices } from '../stores/models/IPrices';
import { IReferenceNumber } from '../stores/models/IReferenceNumber';
import { ISession } from '../stores/models/ISession';
import { PosPayment } from '../stores/models/PosPayment';
import { PosPaymentRequest } from '../stores/models/PosPaymentRequest';
import ErrorUtil from '../utils/ErrorUtil';
import { parsedNumber } from '../utils/NumberUtil';

import ApiError from './ApiError';

export default class ApiService {
  public static API_BASE_URL_DEFAULT = '/brandfree-prepaid/api';

  private static SHORT_TIMEOUT = 30000;
  private apiHttpClient: AxiosInstance;

  constructor(axiosInstance?: AxiosInstance) {
    this.apiHttpClient = axiosInstance || this.getAxiosInstance(ApiService.API_BASE_URL_DEFAULT, ApiService.SHORT_TIMEOUT);
  }

  public getPrepaidInfo(phoneNumber: string): Promise<IPrepaidInfo> {
    return this.getRequest(`/product/prepaid-info/${encodeURIComponent(parsedNumber(phoneNumber))}`, 'prepaidInfo');
  }

  public getPrices(phoneNumber: string): Promise<IPrices> {
    return this.getRequest(`/product/priceplan/${encodeURIComponent(parsedNumber(phoneNumber))}`, 'pricePlan');
  }

  public getReferenceNumber(phoneNumber: string): Promise<IReferenceNumber> {
    return this.getRequest(`/product/refnum/${encodeURIComponent(parsedNumber(phoneNumber))}`, 'refNumber');
  }

  public getPopulationRegistryPerson(personalCode: string): Promise<IPopulationRegistryPerson> {
    return this.getRequest(`/population-registry-person/get-person/${encodeURIComponent(personalCode)}`, 'populationRegistryPerson');
  }

  public postPosPayment(posPayment: PosPaymentRequest): Promise<PosPayment> {
    return this.postRequest(`/pos-payment/create-pos-payment`, 'createPosPayment', posPayment);
  }

  public getSession(): Promise<ISession> {
    return this.getRequest(`/session`, 'session');
  }

  public validateEmail(email: string): Promise<EmailValidation> {
    return this.getRequest(`/emails/${encodeURIComponent(email)}/validate`, 'validate-email');
  }

  // common axios request below
  public getAxiosInstance(baseUrl: string, timeout: number, responseType?: ResponseType): AxiosInstance {
    const config: AxiosRequestConfig = {
      baseURL: baseUrl,
      timeout,
      responseType
    };

    return axios.create(config);
  }

  private getRequest(uri: string, requestName: string, config?: AxiosRequestConfig): Promise<any> {
    return this.handleRequest(this.apiHttpClient.get(uri, { ...config }), requestName);
  }

  private postRequest(uri: string, requestName: string, data?: any) {
    return this.handleRequest(this.apiHttpClient.post(uri, data), requestName);
  }

  public handleRequest(promise: Promise<AxiosResponse>, requestName: string): Promise<any> {
    return this.handleError(this.unwrapData(promise), requestName);
  }

  public unwrapData(promise: Promise<AxiosResponse>): Promise<any> {
    return promise.then((request) => {
      if (request.status == 204) {
        return null;
      }
      return request.data;
    });
  }

  public handleError(promise: Promise<any>, requestName: string): Promise<any> {
    return promise.catch((res) => {
      if (res.response != null && res.response.status === 403) {
        throw new ApiError('Access denied');
      } else {
        let error = res;
        if (res.code === 'ECONNABORTED') {
          error = new Error(`Request ${requestName} timeout`);
        }
        if (res.response != null) {
          error = new Error(`Request ${requestName} failed with status code ${res.response.status}`);
        }

        ErrorUtil.registerError(error);
        throw new ApiError('Received error from backend ' + error);
      }
    });
  }
}
