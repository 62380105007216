import { observer } from 'mobx-react';
import * as React from 'react';

import BankDetailsComponent from '../components/BankDetailsComponent';
import PrePaymentComponent from '../components/PrePaymentComponent';
import StatusNotice from '../components/StatusNotice';

export interface IPrePaidViewProps {
  status?: PaymentStatus;
}

const PrePaidView = observer((props: IPrePaidViewProps) => {
  return (
    <>
      <StatusNotice status={props.status} />
      <PrePaymentComponent />
      <BankDetailsComponent />
    </>
  );
});
export default PrePaidView;
