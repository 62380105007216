import { TranslationsContext } from '@teliaee/telia-translate';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Accordion, AccordionContent, AccordionHeader, AccordionItem, AccordionTitle, Grid, GridColumn, Separator } from 'telia-front-react';

import { useStores } from '../stores/RootStore';

import ReferenceNumberForm from './ReferenceNumberForm';

const BankDetailsComponent = observer(() => {
  const translate = React.useContext(TranslationsContext).translate;
  const { sessionStore, referenceNumberStore } = useStores();
  const getBankDetails = (): JSX.Element => {
    return (
      <>
        <p>{translate('reference.payment-info.title')}:</p>
        <Separator color="invisible" spacing="xxsmall" />
        <Grid>
          <GridColumn width={['md-6']}>
            <p>
              <strong>{translate('reference.payment-info.receiver')}: </strong>
              {translate('reference.payment-info.receiver.value')}
              <br />
              <strong>{translate('reference.payment-info.reference-number')}: </strong>
              {translate('reference.payment-info.reference-number.value')}
              <br />
              <strong>{translate('reference.payment-info.description')}: </strong>
              {translate('reference.payment-info.description.value')}
            </p>
          </GridColumn>
          <GridColumn width={['md-6']}>
            <p>
              <strong>{translate('reference.payment-info.account')}:</strong>
              <br />
              {translate('reference.payment-info.account.value.swed')}
              <br />
              {translate('reference.payment-info.account.value.seb')}
              <br />
              {translate('reference.payment-info.account.value.luminor')}
            </p>
          </GridColumn>
        </Grid>

        <Separator color="invisible" spacing="xxsmall" />
        <p>{translate('reference.payment-info.meta')}</p>
        <Separator color="invisible" spacing="xxsmall" />
        <h5 className="text-bold">{translate('reference.request.title')}</h5>
        <Separator color="invisible" spacing="xxsmall" />
        <p>{translate('reference.request.description')}</p>
      </>
    );
  };

  const getReferenceNumberSearch = (): JSX.Element => {
    return (
      <ReferenceNumberForm
        value={referenceNumberStore.number}
        onChange={referenceNumberStore.updateNumber}
        isInvalid={referenceNumberStore.responseError}
        invalidText={translate('prepaid.validation.incorrect')}
        buttonText={translate('reference.request.button')}
        onSubmit={referenceNumberStore.getReferenceNumber}
        result={referenceNumberStore.referenceNumber ? `${translate('reference.response')} ${referenceNumberStore.referenceNumber}` : null}
      />
    );
  };
  return (
    !sessionStore.isDealer && (
      <Accordion>
        <AccordionItem id="bankdetails">
          <AccordionHeader>
            <AccordionTitle>{translate('reference.title')}</AccordionTitle>
          </AccordionHeader>
          <AccordionContent>
            {getBankDetails()}
            <Separator color="invisible" spacing="xxsmall" />
            {getReferenceNumberSearch()}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    )
  );
});

export default BankDetailsComponent;
