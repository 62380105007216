import { TranslationsContext } from '@teliaee/telia-translate';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Grid, GridColumn, Separator, TextField } from 'telia-front-react';

import { useStores } from '../stores/RootStore';

import EmailComponent from './EmailComponent';

interface IPrepaidDataComponentProps {
  disabled: boolean;
}

const PrepaidDataComponent = observer((props: IPrepaidDataComponentProps) => {
  const { priceStore, sessionStore, prepaidDataStore, mobileNumberStore, populationRegistryPersonStore } = useStores();
  const translate = React.useContext(TranslationsContext).translate;

  const renderMobileNumberField = (): JSX.Element => {
    const numberStore = mobileNumberStore;
    return (
      <TextField
        isDisabled={props.disabled}
        id="prepaidPhone"
        type="tel"
        focusLabel="+372"
        label="+372"
        onChange={numberStore.setMobileNumber}
        helper={
          !numberStore.validNumber
            ? numberStore.mobileNumberFieldErrorHelper(translate('prepaid.validation.incorrect'))
            : priceStore.noPlans
              ? numberStore.mobileNumberFieldNoPlansHelper(translate('prepaid.validation.no-plan'))
              : null
        }
        isInvalid={!numberStore.validNumber || priceStore.noPlans}
      />
    );
  };

  const renderPersonalCodeField = (): JSX.Element => {
    const personStore = populationRegistryPersonStore;
    return (
      <>
        {sessionStore.isDealer && mobileNumberStore.detaineeNumber && (
          <>
            <Separator color="invisible" spacing="xsmall" />
            <TextField
              id="personalCode"
              label={translate('prepaid.step.number.personal-code.title')}
              value={personStore.personalCode}
              onChange={personStore.updatePopulationRegistryPerson}
              helper={personStore.personalCodeFieldHelper(translate('prepaid.validation.incorrect'))}
              isInvalid={!personStore.isValidPersonalCode && personStore.personalCode !== ''}
            />
          </>
        )}
      </>
    );
  };

  const renderNameField = (): JSX.Element => {
    const personStore = populationRegistryPersonStore;
    return (
      <>
        {sessionStore.isDealer && mobileNumberStore.detaineeNumber && (
          <>
            <Separator color="invisible" spacing="xxsmall" />
            <TextField
              id="personName"
              label={translate('prepaid.step.number.person-name.title')}
              value={personStore.personName}
              isDisabled={true}
              helper={personStore.personNameFieldHelper(translate('prepaid.validation.name-error'), translate('prepaid.validation.waiting-for-name'))}
            />
          </>
        )}
      </>
    );
  };

  const renderForwardButton = (): JSX.Element => {
    return (
      <Grid>
        <GridColumn align={['middle-xs']} className="text-right">
          <Button
            size="small"
            iconRight="#arrow-right"
            text={translate('prepaid.steps.next')}
            disabled={props.disabled}
            onClick={prepaidDataStore.submitForm}
          />
        </GridColumn>
      </Grid>
    );
  };

  return (
    <>
      {renderMobileNumberField()}
      {renderPersonalCodeField()}
      {renderNameField()}
      {prepaidDataStore.showEmailComponent && <EmailComponent disabled={props.disabled} />}
      <Separator color="invisible" spacing="xxsmall" />
      {renderForwardButton()}
    </>
  );
});
export default PrepaidDataComponent;
