import { TranslationsContext } from '@teliaee/telia-translate';
import React from 'react';
import { LabelProvider } from 'telia-front-react';

interface Props {
  children?: React.ReactElement | React.ReactElement[];
}

const TeliaLabelProvider = (props: Props): JSX.Element => {
  const { children } = props;
  const translations = React.useContext(TranslationsContext).translations;
  return <LabelProvider labels={translations?.global || {}}>{children}</LabelProvider>;
};

export default TeliaLabelProvider;
